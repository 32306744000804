export default {
  methods: {
    async loadStorage() {
      if(!this.storageKey) return
      const data = this.$storage.get(this.storageKey)
      if(!data) return
      this.requestData = data
    },
    cleanExpiredStoreStorage() {
      try {
        const allStorage = this.$storage.all()
        for(const key in allStorage) {
          if((new RegExp('create-order-admin').test(key)) === false) continue
          const data = JSON.parse(allStorage[key])
          this.handleStorageRequestData(key, data)
        }
      } catch (error) {
        console.warn(error)
      }
    },
    handleStorageRequestData(key, data) {
      try {
        let shouldDelete = false
        const lastUpdatedAt = data.last_updated_at
        if(!lastUpdatedAt) shouldDelete = true
        const before5Days = this.$dayjs().subtract(5, 'days').format('YYYY-MM-DD HH:mm:ss') // N天前
        const isExpired = this.$dayjs(lastUpdatedAt).isBefore(before5Days) // 超過N天前沒更新的就算過期
        if(!isExpired) return
        this.$storage.delete(key)
      } catch (error) {
        console.warn(error)
      }
    },
    storeRequestData() {
      if(!this.storageKey) return
      if(window.eagleLodash.isEqual(this.requestData, this.$storage.get(this.storageKey))) return
      const data = window.eagleLodash.cloneDeep(this.requestData)
      data.last_updated_at = this.$helper.now()
      this.$storage.set(this.storageKey, data)
    },
    removeStoreRequestData() {
      if(!this.storageKey) return
      this.$storage.delete(this.storageKey)
    },
  },
  computed: {
    storageKey() {
      let identity = this.memberId
      if(!identity) return `create-order-admin-anonymous`
      return `create-order-admin-${identity}`
    },
  },
}
