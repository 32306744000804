export default {
  computed: {
    amountPayable() {
      if(!this.queryResult) return 0
      return this.queryResult.amount_payable
    },
    minAmountLimit() {
      if(!this.queryResult) return null
      if(!this.paymentConfig) return null
      if(!this.paymentConfig.minAmount) return null
      return this.paymentConfig.minAmount
    },
    minAmountLimitValid() {
      if(!this.amountPayable) return true
      return this.amountPayable >= this.minAmountLimit
    },
    cvsDataValid() {
      if(!this.$helper.validator.required(this.requestData.delivery_info.store_no)) return false
      if(!this.$helper.validator.required(this.requestData.delivery_info.store_name)) return false
      if(!this.$helper.validator.required(this.requestData.delivery_info.store_address)) return false
      return true
    },
    nameValid() {
      const name = this.requestData.delivery_info.name
      if(!this.$helper.validator.required(name)) return false
      if(!this.$helper.validator.strictStringValid(name)) return false
      return this.$helper.getStringBytes(name) < 20
    },
    cityDistrictValid() {
      if(!this.$helper.validator.required(this.requestData.delivery_info.city)) return false
      if(!this.$helper.validator.required(this.requestData.delivery_info.district)) return false
      return true
    },
    zipValid() {
      const zip = this.requestData.delivery_info.zip
      if(this.$helper.getStringBytes(zip) != 3 && this.$helper.getStringBytes(zip) != 5) return false
      return this.$helper.validator.onlyNumber(zip)
    },
    addressValid() {
      const address = this.requestData.delivery_info.address
      if(typeof address != 'string') return false
      if(address.length < 3) return null
      return address.length < 60
    },
    choiceProduct() {
      if(!Array.isArray(this.requestData.items)) return false
      return this.requestData.items.length > 0
    },
    ecpayDeliveryAmountLimit() {
      if(!this.queryResult) return true
      return this.queryResult.amount_payable <= 20000
    },
    phoneValid() {
      return this.$helper.validator.phoneNumberLocal(this.requestData.delivery_info.phone)
    },
    enoughStock() {
      if(!this.queryResult) return true
      return this.queryResult.enough_stock === true
    },
    buyerEmailValid() {
      if(!this.requestData) return true
      return this.$helper.validator.email(this.requestData.email)
    },
    disabledCreate() {
      if(this.createOrderLoading) return true
      if(this.disabledQuery) return true
      if(!this.requestData) return true
      if(!Array.isArray(this.requestData.items)) return true
      if(this.requestData.items.length == 0) return true
      if(!this.buyerEmailValid) return true
      if(!this.choiceProduct) return true
      if(!this.enoughStock) return true
      if(!this.minAmountLimitValid) return true
      if(!this.nameValid || !this.phoneValid) return true
      if(this.isHomeDeliveryType) {
        if(!this.cityDistrictValid) return true
        if(!this.zipValid) return true
        if(!this.addressValid) return true
      }
      if(this.isCvsDeliveryType) {
        if(!this.cvsDataValid) return true
      }
      if(this.cashOnDeliveryDisabled) return true
      return false
    },
    checklist() {
      let checklist = [
        {
          label: this.$t('order_create.check.buyer'),
          valid: this.buyerEmailValid,
          enabled: true,
        },
        {
          label: this.$t('order_create.check.items'),
          valid: this.choiceProduct,
          enabled: true,
        },
        {
          label: this.$t('order_create.check.payment'),
          valid: this.$helper.validator.required(this.requestData.payment_type),
          enabled: true,
        },
        {
          label: this.$t('order_create.check.min_amount_limit', { min: this.minAmountLimit }),
          valid: this.minAmountLimitValid,
          enabled: this.minAmountLimit > 0,
        },
        {
          label: this.$t('order_create.check.delivery'),
          valid: this.$helper.validator.required(this.requestData.delivery_type),
          enabled: true,
        },
        {
          label: this.$t('order_create.check.cash_on_delivery_disabled'),
          valid: !this.cashOnDeliveryDisabled,
          enabled: this.cashOnDeliveryDisabled === true,
        },
        {
          label: this.$t('order_create.check.deliver_info.name'),
          valid: this.nameValid,
          enabled: true,
        },
        {
          label: this.$t('order_create.check.deliver_info.phone'),
          valid: this.phoneValid,
          enabled: true,
        },
        {
          label: this.$t('order_create.check.enough_stock'),
          valid: this.enoughStock,
          enabled: this.choiceProduct,
        },
        {
          label: this.$t('order_create.check.cvs'),
          valid: this.cvsDataValid,
          enabled: this.isCvsDeliveryType === true,
        },
        {
          label: this.$t('order_create.check.ecpay_delivery_amount_limit'),
          valid: this.ecpayDeliveryAmountLimit,
          enabled: this.isEcpayDeliveryProvider === true,
        },
        {
          label: this.$t('order_create.check.deliver_info.city_district'),
          valid: this.cityDistrictValid,
          enabled: this.isHomeDeliveryType === true,
        },
        {
          label: this.$t('order_create.check.deliver_info.zip'),
          valid: this.zipValid,
          enabled: this.isHomeDeliveryType === true,
        },
        {
          label: this.$t('order_create.check.deliver_info.address'),
          valid: this.addressValid,
          enabled: this.isHomeDeliveryType === true,
        },
      ]

      checklist = checklist.filter(rule => rule.enabled === true)
      if(checklist.length === 0) return null
      return checklist
    },
  },
}
