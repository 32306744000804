<template>
  <div>
    <v-tabs
      v-model="requestData.focusTab"
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.value"
      >
        {{ tab.label }}
      </v-tab>
    </v-tabs>

    <v-tabs-items class="pt-4" v-model="requestData.focusTab">
      <!-- tab-訂購商品設定-->
      <v-tab-item key="items">
        <!-- 非會員 -->
        <v-card v-if="!member" class="mb-8">
          <v-card-title>{{'order.data.buyer'| t}}</v-card-title>
          <v-card-subtitle>{{'order.data.buyer.help'| t}}</v-card-subtitle>

          <v-card-text>
            <v-text-field
              dense
              outlined
              :label="$t('order.data.buyer.email')"
              counter
              maxlength="30"
              v-model="requestData.email"
            ></v-text-field>
          </v-card-text>
        </v-card>

        <!-- 會員 -->
        <v-card v-if="member" class="mb-8">
          <v-card-title>
            {{'module.member'| t}}
          </v-card-title>
          <v-card-text>
            <ul>
              <li>ID: {{member.id}}</li>
              <li v-if="member.name">{{ 'data.person_name'|t }}: {{member.name}}</li>
              <li>{{ 'data.email'|t }}: {{member.email}}</li>
            </ul>
          </v-card-text>
        </v-card>

        <!-- 訂單商品 -->
        <items
          :queryResult="queryResult"
          :disabledQuery="disabledQuery"
          v-model="requestData"
        ></items>
      </v-tab-item>

      <!-- tab-付款/配送-->
      <v-tab-item key="payment_delivery" eager>
        <v-row>
          <v-col cols="12" xl="6" lg="6" md="6">
            <!-- 付款方式 -->
            <paymentType
              @setPaymentTypes="setPaymentTypes"
              :paymentTypes="paymentTypes"
              :paymentConfig="paymentConfig"
              v-model="requestData"
            ></paymentType>
          </v-col>

          <v-col cols="12" xl="6" lg="6" md="6">
            <!-- 配送方式 -->
            <deliveryType
              @setDeliveryTypes="setDeliveryTypes"
              :deliveryTypes="deliveryTypes"
              :deliveryConfig="deliveryConfig"
              :isHomeDeliveryType="isHomeDeliveryType"
              v-model="requestData"
            ></deliveryType>
          </v-col>
        </v-row>
      </v-tab-item>

      <!-- tab-配送設定-->
      <v-tab-item key="delivery_info">
        <!-- 配送資料 -->
        <deliveryInfo
          :isHomeDeliveryType="isHomeDeliveryType"
          :isCvsDeliveryType="isCvsDeliveryType"
          :isDefaultDeliveryProvider="isDefaultDeliveryProvider"
          :ecpayCvsMap="ecpayCvsMap"
          v-model="requestData"
        ></deliveryInfo>
      </v-tab-item>
    </v-tabs-items>

    <v-card class="mb-8">
      <v-card-text v-if="checklist">
        <checklist
          v-for="(checklistRule, checklistRuleIndex) in checklist"
          :key="checklistRuleIndex"
          :rule="checklistRule"
        ></checklist>
      </v-card-text>

      <template v-if="queryResult">
        <v-card-title>{{'ec.data.query'| t}}</v-card-title>
        <v-card-text class="body-1 black--text">
          <div class="mb-2">{{'order.data.amount'| t}}: {{ $helper.amount(queryResult.amount) }}</div>
          <div class="mb-2">{{'ec.data.delivery_fee'| t}}: {{ $helper.amount(queryResult.delivery_fee) }}</div>
          <div class="mb-2">{{'order.data.amount_payable'| t}}: {{ $helper.amount(queryResult.amount_payable) }}</div>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="primary"
            block
            @click="createOrderConfirm"
            :disabled="disabledCreate"
          >
            {{'order.action.create'| t}}
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>

    <form-post
      ref="pay"
      v-if="payProperties"
      v-bind="payProperties"
    ></form-post>
  </div>
</template>

<script lang="babel" type="text/babel">
import createOrderValidateRules from './createOrderValidateRules'
import createOrderStorage from './createOrderStorage'
export default {
  mixins: [
    createOrderStorage,
    createOrderValidateRules,
  ],
  metaInfo() {
    return {
      title: this.$t('order.action.create'),
    }
  },
  data: () => ({
    member: null,
    requestData: {
      email: '',
      focusTab: 'items',
      items: [],
      payment_type: '',
      delivery_type: '',
      delivery_info: {
        name: '',
        phone: '',
        email: '',
        zip: '',
        city: '',
        district: '',
        address: '',
        store_no: '',
        store_name: '',
        store_address: '',
      },
    },
    queryResult: null,
    createOrderLoading: false,
    order: null,
    paymentTypes: [],
    deliveryTypes: [],
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.cleanExpiredStoreStorage()
      this.loadStorage()
      this.$store.dispatch('loading/active')
      if(!this.memberId) {
        this.$store.dispatch('loading/close')
        return
      }


      try {
        this.member = await this.$api.collection.memberApi.read(this.memberId)
        this.$set(this.requestData, 'email', this.member.email)
      } catch (error) {
        console.warn(error)
      }
      this.$store.dispatch('loading/close')
    },
    setPaymentTypes(types) {
      this.paymentTypes = types
    },
    setDeliveryTypes(types) {
      this.deliveryTypes = types
    },
    triggerQuery() {
      window.clearTimeout(this.queryDelayTimeout)
      this.queryDelayTimeout = null
      this.queryDelayTimeout = window.setTimeout(() => {
        this.query()
      }, 1000)
    },
    async query() {
      if(this.disabledQuery) return
      this.$snackbar.active({
        text: this.$t('order.query.loading'),
        timeout: 1000,
      })
      this.$store.dispatch('loading/progress')
      let params = window.eagleLodash.cloneDeep(this.requestData)
      if(this.member) {
        params.email = this.member.email
      }
      try {
        this.queryResult = await this.$api.collection.orderApi.query(params)
        if(!this.enoughStock) {
          this.$snotify.warning(
            this.$t('order.notify.out_of_stock.content'),
            this.$t('order.notify.out_of_stock')
          )
        }
      } catch (error) {
        console.warn(error)
      } finally {
        this.$store.dispatch('loading/closeProgress')
      }
    },
    createOrderConfirm() {
      this.$apopup.base({
        title: this.$t('order.confirm.create'),
        applyCallback: this.startCreateOrder,
      })
    },
    async startCreateOrder() {
      if(this.createOrderLoading) return
      this.createOrderLoading = true
      this.order = await this.createOrderRequest()
      if(!this.order) {
        this.createOrderLoading = false
        return
      }
      await this.pay()
      this.createOrderLoading = false
    },
    async createOrderRequest() {
      try {
        const order = await this.$api.collection.orderApi.create(this.requestData)
        return order
      } catch (error) {
        console.error(error)
        let title = this.$t('order.notify.create_failure')
        let message = this.$t('error.unexpect')
        if(error.status == 406) {
          message = this.$t('order.notify.out_of_stock')
          this.query()
        }
        this.$snotify.error(message, title)
      }
      return null
    },
    async pay() {
      this.removeStoreRequestData()
      await this.$nextTick()
      try {
        this.$refs.pay.submit()
      } catch (error) {
        console.warn(error)
      }
    },
  },
  computed: {
    isCashOnDeliveryPaymentType() {
      return this.requestData.payment_type === this.$paymentConstants.TYPE_CASH_ON_DELIVERY
    },
    ecpayCvsMap() {
      if(!this.deliveryConfig) return false
      return this.deliveryConfig.ecpayCvsMap === true
    },
    isCvsDeliveryType() {
      if(!this.deliveryConfig) return false
      return this.deliveryConfig.isCvs === true
    },
    isEcpayDeliveryProvider() {
      if(!this.deliveryConfig) return false
      return this.deliveryConfig.provider === this.$deliveryConstants.PROVIDER_ECPAY
    },
    isHomeDeliveryType() {
      if(!this.deliveryConfig) return false
      return this.deliveryConfig.isHomeDelivery === true
    },
    cashOnDeliveryDisabled() {
      if(!this.deliveryConfig) return false
      if(!this.deliveryConfig.cashOnDeliveryDisabled) return false
      return this.isCashOnDeliveryPaymentType
    },
    isDefaultDeliveryProvider() {
      if(!this.deliveryConfig) return false
      return this.deliveryConfig.isDefault === true
    },
    paymentConfig() {
      if(!this.requestData) return null
      if(!this.requestData.payment_type) return null
      if(!Array.isArray(this.paymentTypes)) return null
      if(this.paymentTypes.length == 0) return null
      const type = this.paymentTypes.find(type => type.value === this.requestData.payment_type)
      if(!type) return null
      return type
    },
    deliveryConfig() {
      if(!this.requestData) return null
      if(!this.requestData.delivery_type) return null
      if(!Array.isArray(this.deliveryTypes)) return null
      if(this.deliveryTypes.length == 0) return null
      const type = this.deliveryTypes.find(type => type.value === this.requestData.delivery_type)
      if(!type) return null
      return type
    },
    payProperties() {
      if(!this.order) return {}
      return {
        route: `/api/ec/pay/${this.order.id}`,
        params: {
          token: this.tokenStore.get(),
          fingerprint: this.$fingerprint.get(),
          code: this.order.code,
          returnUrl: this.$helper.getPayReturnUrl(this.order.id),
        },
      }
    },
    tokenStore() {
      return this.$store.getters['token/tokenStore']
    },
    memberId() {
      return this.$route.query.member
    },
    tabs() {
      return ['items', 'payment_delivery', 'delivery_info'].map(tab => ({
        label: this.$t(`order.create_page.tab.${tab}`),
        value: tab,
      }))
    },
    // 去除掉不須偵測的項目
    requestDataFocus() {
      const result = window.eagleLodash.cloneDeep(this.requestData)
      delete result.focusTab
      return window.eagleLodash.cloneDeep(result)
    },
    disabledQuery() {
      if(!this.$helper.validator.required(this.requestData.email)) return true
      return false
    },
  },
  components: {
    paymentType: () => import('modules/order/views/createOrderAdmin/paymentType.vue'),
    deliveryType: () => import('modules/order/views/createOrderAdmin/deliveryType.vue'),
    deliveryInfo: () => import('modules/order/views/createOrderAdmin/deliveryInfo.vue'),
    items: () => import('modules/order/views/createOrderAdmin/items.vue'),
    checklist: () => import('modules/order/views/createOrderAdmin/checklist.vue'),
  },
  watch: {
    requestData: {
      deep: true,
      handler() {
        this.storeRequestData()
      },
    },
    requestDataFocus: {
      deep: true,
      handler(after, before) {
        if(window.eagleLodash.isEqual(after, before)) return
        this.triggerQuery()
      },
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
